import Vue from 'vue'
import VueRouter from 'vue-router'

import Score from '../components/Score'

Vue.use(VueRouter)

const routes = [
  { name: 'Score', path: '/', component: Score },
  { name: 'Score', path: '/:url', component: Score },
  { name: 'Score results', path: '/result/:hash', component: Score }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
