import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'

// CSS
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@fontsource/material-icons'
import '@fontsource/material-icons-outlined'
import './css/olympus-ui.scss'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Font awesome config
library.add(faTwitter, faLinkedinIn)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true

Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(VueSocialSharing)

Vue.prototype.$api = {
  async assets (uri, data, config) {
    return (
      await axios.post(`https://asset-generator.exatom.io${uri}`, data, { ...await this._config(), ...config })
    )
  },
  async _config () {
    return {
      headers: {
        Accept: 'application/x.olympus-api.v1.1+json'
      }
    }
  },

  tagsAdded: [],
  addTags (tags = []) {
    // Exatom
    if (!this.tagsAdded.includes('exatomEvent') && (!tags.length || tags.includes('exatomEvent'))) {
      try {
        const exatomEvent = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        exatomEvent.src = 'https://assets.exatom.io/event.js?clientCode=D095'
        s.parentNode.insertBefore(exatomEvent, s)
      } catch (e) {}
      this.tagsAdded.push('exatomEvent')
    }

    // Exatom conversion tag
    if (!this.tagsAdded.includes('exatomConversion') && tags.includes('exatomConversion')) {
      try {
        const exatomConversion = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        exatomConversion.src = 'https://assets.exatom.io/conversion.js?clientCode=D095&tagCode=M6WPL'
        s.parentNode.insertBefore(exatomConversion, s)
      } catch (e) {}
      this.tagsAdded.push('exatomConversion')
    }

    // Visitor analytics
    if (!this.tagsAdded.includes('visitorAnalytics') && (!tags.length || tags.includes('visitorAnalytics'))) {
      try {
        const visitorAnalytics = document.createElement('script')
        const s = document.getElementsByTagName('script')[0]
        visitorAnalytics.text = '(function(v,i,s,a,t){v[t]=v[t]||function(){(v[t].v=v[t].v||[]).push(arguments)};if(!v._visaSettings){v._visaSettings={}}v._visaSettings[a]={v:"1.0",s:a,a:"1",t:t};var b=i.getElementsByTagName("body")[0];var p=i.createElement("script");p.defer=1;p.async=1;p.src=s+"?s="+a;b.appendChild(p)})(window,document,"//app-worker.visitor-analytics.io/main.js","f1317613-a92f-11ec-b589-901b0edac50a","va")'
        s.parentNode.insertBefore(visitorAnalytics, s)
      } catch (e) {}
      this.tagsAdded.push('visitorAnalytics')
    }
  }
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#scoreApp')
